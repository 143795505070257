import React, { ReactNode, useEffect } from 'react';
import css from './../css/dialog.module.css';

interface Button {
    "caption": string;
    "callback": () => void;
    "customStyle": { readonly [key: string]: string };
    "closeDialog": boolean
}

interface Props {
    "title": string;
    "description"?: string;
    "content"?: ReactNode;
    "customStyle": { readonly [key: string]: string };
    "Buttons": Button[];
    "children": ReactNode;
}

const element_ids = {
    "background": 'dialog_background__Ggb8P',
    "dialog": 'dialog__Ggb8P',
};

export default function Dialog({
    title,
    description,
    content,
    customStyle,
    Buttons,
    children
}: Props) {
    useEffect(() => {
        const background = document.getElementById(element_ids.background);

        if (!background) return;
        background.className = `${css.duration}`;
        background.className = `${css.dialogBackground} ${css.dialogBackground_show} ${css.duration}`;
        setTimeout(() => background.className = `${css.dialogBackground} ${css.dialogBackground_show}`, 1000 / 4);
    }, [])

    return (
        <div className={css.dialogBackground} id={element_ids.background}>
            <div className={css.dialog} id={element_ids.dialog} style={customStyle}>
                <div className={css.textContainer}>
                    <span className={css.title}>{title}</span>
                    <span className={css.description}>{description ? description : content}</span>
                </div>
                <div className={css.buttonContainer}>
                    {
                        Buttons.map((val, idx) => (
                            <button onClick={() => {
                                if (val.closeDialog) {
                                    const background = document.getElementById(element_ids.background);
                                    if (!background) return;
                                    background.className = `${css.dialogBackground} ${css.duration}`;
                                    setTimeout(() => background.className = `${css.dialogBackground} ${css.dialogBackground_hide}`, 1000 / 4);
                                }

                                val.callback();
                            }} key={idx}>
                                {val.caption}
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}