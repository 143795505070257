import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Dialog from './../components/Dialog.tsx';
import css from './../css/search.module.css';

/**@param {Date} a  */
function getDateStr(a) {
    return `${a.getFullYear()}년 ${(a.getMonth() + 1) > 9 ? (a.getMonth() + 1) : `0${(a.getMonth() + 1)}`}월` + 
    ` ${a.getDate() > 9 ? a.getDate() : `0${a.getDate()}`}일` + 
    ` ${["일", "월", "화", "수", "목", "금", "토"][a.getDay()]}요일`;
}

const SearchResult = ({
    title,
    id,
    thumbnail,
    uploadedAt,
    channelName,
    onClick
}) => (
    <div className={css.searchResult} onClick={onClick}>
        <img src={thumbnail} className={css.thumbnail}/>
        <div className={css.textContainer}>
            <span className={css.title}>{title}</span>
            <span className={css.description}>ID: {id} | 채널: {channelName}</span>
            <span className={css.description}>업로드: {getDateStr(new Date(uploadedAt))}</span>
            <span className={css.greyText}>영상을 클릭해 다운로드 페이지로 넘어갑니다.</span>
        </div>
    </div>
);

const App = () => {
    const [searchParams] = useSearchParams();
    const q = decodeURIComponent(searchParams.get("q"));
    const [searchResults, render_searchResults] = useState();
    const [dialog, render_dialog] = useState();
    const [inputValue, setInputValue] = useState(q);
    const navigate = useNavigate();
    
    useEffect(() => {
        (async () => {
            const r = await axios.get("https://api.pro203s.kr/ytdl/search?q=" + q, {
                "validateStatus": () => true
            });
    
            if (r.status !== 200) {
                switch (r.data.message) {
                    case "REQUIRED_Q": render_dialog(<Dialog
                        title="오류"
                        description="요청할 때 검색어가 포함되지 않았습니다."
                        Buttons={[
                            {
                                "caption": "닫기",
                                "callback": () => {},
                                "closeDialog": true
                            }
                        ]}></Dialog>); break;
                    case "ERROR": render_dialog(<Dialog
                        title="오류"
                        description="내부 서버 오류가 발생했습니다."
                        Buttons={[
                            {
                                "caption": "닫기",
                                "callback": () => {},
                                "closeDialog": true
                            },
                            {
                                "caption": "콘솔에 로그",
                                "callback": () => console.log(r.data.error),
                                "closeDialog": false
                            }
                        ]}></Dialog>); break;
                }
                return;
            }
    
            render_searchResults(
                <>
                    {
                        r.data.map((val) => (
                            <SearchResult 
                            title={val.title}
                            id={val.id}
                            thumbnail={val.thumbnail.url}
                            uploadedAt={val.uploadedAt}
                            channelName={val.channelInfo.title}
                            key={val.key}
                            onClick={() => {
                                navigate("/download?id=" + val.id)
                            }}/>
                        ))
                    }
                </>
            );
        })();
    }, []);

    return (
        <>
        {dialog}
        <div className={css.navigator}>
            <input className={css.input} 
            onChange={
                (ev) => setInputValue(ev.currentTarget.value)
            } placeholder='검색어 입력' value={inputValue}
            onKeyDown={(ev) => {
                if (ev.keyCode !== 13) return;

                document.getElementById("searchsearchsearch").click();
            }}/>
            <button id="searchsearchsearch" onClick={() => {
                //navigate({
                //    "pathname": "/search",
                //    "search": "?q=" + inputValue
                //});
                window.location.href = "/search?q=" + inputValue;
            }}>검색하기</button>
            <button onClick={() => navigate("/")}>뒤로가기</button>
        </div>
        <div className={css.results}>
            {searchResults}
        </div>
        </>
    );
};

export default App;