import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import css from './../css/download.module.css';
import Dialog from '../components/Dialog.tsx';

/**
 * @param {readonly import('axios').AxiosResponse<any, any>} result
 * @param {string} id
 * @param {"video" | "audio"} filetype
 */
const fileDownload = (result, id, filetype) => {
    const blob = new Blob([result.data]);

    const fileUrl = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = fileUrl;
    link.style.display = 'none';
    
    const fileName = `${id}.${filetype === "video" ? "mp4" : "mp3"}`;
    link.download = fileName;
    
    document.body.appendChild(link);
    link.click();
    link.remove();
};

const App = () => {
    const [searchParams] = useSearchParams();
    const [videoInfo, setVideoInfo] = useState({
        "title": "불러오는 중...",
        "description": "불러오는 중...",
        "thumbnail": "",
        "author": "불러오는 중..."
    });
    const [dialog, render_dialog] = useState(<></>);
    const id = searchParams.get("id");
    const navigate = useNavigate();

    const [infoCard, render_infoCard] = useState(
        <div className={css.toCenter}>
            <div className={`${css.marginCenter} ${css.infoCard}`}>
                <div className={css.thumbnailContainer}></div>
                <span className={css.title}>잠시만 기다려 주세요...</span>
            </div>
            <button className={css.back} onClick={() => navigate("/")}>홈으로 가기</button>
        </div>);
    const DESCRIPTION_MAX_LENGTH = 35;

    useEffect(() => render_infoCard(<div className={css.toCenter}>
        <div className={`${css.marginCenter} ${css.infoCard}`}>
            <div className={css.thumbnailContainer}>
                <img className={css.thumbnail} src={videoInfo.thumbnail}/>
            </div>
            <div className={css.videoInfoContainer}>
                <span className={css.title}>{videoInfo.title}</span>
                <span className={css.description} onClick={async () => {
                    try {
                        await navigator.clipboard.writeText(videoInfo.description);
                        alert('클립보드에 복사되었습니다.');
                    } catch (e) {
                      alert('복사에 실패하였습니다');
                    }
                }}>설명: {videoInfo.description.length > DESCRIPTION_MAX_LENGTH ? 
                    (videoInfo.description.substring(0, DESCRIPTION_MAX_LENGTH) + `... (${videoInfo.description.length - DESCRIPTION_MAX_LENGTH}글자 남음)`) : 
                    videoInfo.description}</span>
                <span>제작자: {videoInfo.author}</span>
            </div>
            <div className={css.buttonContainer}>
                <button onClick={async () => {
                    render_dialog(<Dialog 
                        title="다운로드 중..."
                        description={`약 1분 정도 소요됩니다.\n파일 이름: ${id}.mp4`}
                        Buttons={[]}/>)
                    
                    const result = await axios(`https://api.pro203s.kr/ytdl/download?id=${id}&filetype=video`, {
                        "responseType": "blob"
                    });
                    fileDownload(result, id, "video");
                    render_dialog(<></>);
                }}>mp4 다운로드</button>
                <button onClick={async () => {
                    render_dialog(<Dialog 
                        title="다운로드 중..."
                        description={`약 1분 정도 소요됩니다.\n파일 이름: ${id}.mp3`}
                        Buttons={[]}/>)
                    
                    const result = await axios(`https://api.pro203s.kr/ytdl/download?id=${id}&filetype=audio`, {
                        "responseType": "blob"
                    });
                    fileDownload(result, id, "audio");
                    render_dialog(<></>);
                }}>mp3 다운로드</button>
            </div>
        </div>
        <button className={css.back} onClick={() => navigate("/")}>홈으로 가기</button>
    </div>), [videoInfo]);

    useEffect(() => {
        (async () => {
            const result = await axios.get(`https://api.pro203s.kr/ytdl/info?id=${id}`, {
                "validateStatus": () => true
            });

            if (result.status !== 200) {
                const message = result.data.message;

                switch (message) {
                    case "INVAILD_ID": render_dialog(<Dialog
                        title="오류"
                        description="영상의 아이디가 잘못되었습니다."
                        Buttons={[
                            {
                                "caption": "닫기",
                                "callback": () => {},
                                "closeDialog": true
                            }
                        ]}></Dialog>); break;
                    case "REQUIRED_ID": render_dialog(<Dialog
                        title="오류"
                        description="서버에 ID를 보내주지 않았습니다."
                        Buttons={[
                            {
                                "caption": "닫기",
                                "callback": () => {},
                                "closeDialog": true
                            }
                        ]}></Dialog>); break;
                    case "ERROR": render_dialog(<Dialog
                        title="오류"
                        description="서버 내부 오류가 발생했습니다."
                        Buttons={[
                            {
                                "caption": "닫기",
                                "callback": () => {},
                                "closeDialog": true
                            },
                            {
                                "caption": "콘솔에 로그",
                                "callback": () => console.log(result.data.error)
                            }
                        ]}></Dialog>); break;
                        
                }
                return;
            }

            setVideoInfo(result.data);
        })();
    }, []);

    return (
        <>{dialog}{infoCard}</>
    );
};

export default App;