import { Route, Routes } from "react-router-dom";
import Index from './pages/index.jsx'
import Download from './pages/download.jsx';
import Search from './pages/search.jsx';
import axios from 'axios';
axios.defaults.validateStatus = () => true;

const App = () => {
	return (
		<Routes>
      		<Route path="/" element={<Index />} />
			<Route path="/download" element={<Download />} />
			<Route path="/search" element={<Search />} />
		</Routes>
	);
};

export default App;