import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import css from './../css/index.module.css';

const urlRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
function YouTubeGetID(url){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/shorts\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}
const checkVideoId = id => {
	return /^[a-zA-Z0-9_-]{11}$/.test(id);
};

const App = () => {
    const [message, setMessage] = useState("");
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate();

    return (
        <div className={css.toCenter}>
            <div className={css.marginCenter}>
                <span className={css.message}>{message}</span>
                <input type="text" className={css.input} placeholder='검색어 또는 URL' onKeyDown={(ev) => {
                    if (ev.keyCode !== 13) return;
                    if (urlRegex.test(inputValue)) {
                        const id = YouTubeGetID(inputValue);
                        if (!checkVideoId(id)) return setMessage("영상 ID가 잘못되었습니다.");
                        
                        return navigate("/download?id=" + id);
                    }

                    navigate("/search?q=" + inputValue);
                }}
                onChange={(ev) => {
                    setInputValue(ev.currentTarget.value);
                }}/>
                <div className={css.buttonContainer}>
                    <button onClick={() => {
                        if (!inputValue) return setMessage("검색어를 입력해주세요.");
                        navigate("/search?q=" + encodeURIComponent(inputValue));
                    }}>검색하기</button>
                    <button onClick={() => {
                        if (!urlRegex.test(inputValue)) return setMessage("URL이 잘못되었습니다.");
                        const id = YouTubeGetID(inputValue);
                        if (!checkVideoId(id)) return setMessage("영상 ID가 잘못되었습니다.");

                        navigate("/download?id=" + id);
                    }}>다운로드</button>
                </div>
            </div>
        </div>
    )
};

export default App;